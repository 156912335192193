import { Box, Text, VStack } from "@chakra-ui/react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";
import Card from "components/Card/Card";
import IconBox from "components/Icons/IconBox";
import React from "react";

const AbnormalReportCard = ({
  mainAmount,
  mainText,
  mainIcon,
  mainDescription,
  isInteger,
  isLoading,
}) => {
  const cleanNumber = parseFloat(mainAmount?.replace(/,/g, ""));
  return (
    <Card
      height="100%"
      p={8}
      bgColor="gray.900"
      border="1px solid rgba(55, 63, 72, 1)"
    >
      <VStack height="100%" spacing={4} align="left">
        <IconBox backgroundColor="none">{mainIcon}</IconBox>
        {isLoading && !cleanNumber ? (
          <span className="loader"></span>
        ) : (
          <AnimatedNumber
            n={cleanNumber}
            dp={isInteger ? 0 : 2}
            fontSize="41px"
          />
        )}
        <Box>
          <Text fontSize="14px" color="#fff">
            {mainText}
          </Text>
          <Text fontSize="14px" color="#8C9592">
            {mainDescription}
          </Text>
        </Box>
      </VStack>
    </Card>
  );
};

export default AbnormalReportCard;
