import { Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Loader } from "components/Loader/Loader";
import SimpleTable from "components/Tables/SimpleTable";
import React from "react";
import { gameSettingColumnsData } from "variables/columns/system-setting/gameSettingColumnsData";

const ProviderCard = ({ title, providerData, isLoading, handleToggle }) => {
  return (
    <Card p={0} boxShadow="none">
      <CardHeader pt="12px">
        <Flex direction="column">
          <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
            รายชื่อค่าย - {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody h="375px">
        {isLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            height="400px"
            w="100%"
          >
            <Loader />
          </Flex>
        ) : (
          <SimpleTable
            tableData={providerData}
            columnsData={gameSettingColumnsData(handleToggle)}
            rowSize={providerData?.length}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ProviderCard;
