import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import { useCommissionSetting } from "hooks/business/system-setting/useCommissionSetting";
import React from "react";

import CommissionForm from "./components/CommissionForm";

const CommissionSetting = ({ pageName }) => {
  const {
    commissionData,
    isCommissionLoading,
    isSaving,
    handleCommissionSubmit,
  } = useCommissionSetting();

  const handleCommissionTurnOver = (values) => {
    handleCommissionSubmit("commissionTurnOver", values);
  };

  const handleCommissionAffiliate = (values) => {
    handleCommissionSubmit("commissionAffiliate", values);
  };

  const handleCommissionWinlose = (values) => {
    handleCommissionSubmit("commissionWinlose", values);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card p={6}>
          <Grid templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }} gap={6}>
            <CommissionForm
              title="(แนะนำเพื่อน)"
              initialValues={{
                commissions: commissionData?.commissionAffiliate?.sort(
                  (a, b) => a.level - b.level,
                ),
              }}
              handleSubmit={handleCommissionAffiliate}
              isCommissionDataLoading={isCommissionLoading}
              isSaving={isSaving}
            />
            <CommissionForm
              title="(แพ้/ชนะ)"
              initialValues={{
                commissions: commissionData?.commissionWinlose?.sort(
                  (a, b) => a.level - b.level,
                ),
              }}
              handleSubmit={handleCommissionWinlose}
              isCommissionDataLoading={isCommissionLoading}
              isSaving={isSaving}
            />
            <CommissionForm
              title="(เทิร์นโอเวอร์)"
              initialValues={{
                commissions: commissionData?.commissionTurnOver?.sort(
                  (a, b) => a.level - b.level,
                ),
              }}
              handleSubmit={handleCommissionTurnOver}
              isCommissionDataLoading={isCommissionLoading}
              isSaving={isSaving}
            />
          </Grid>
        </Card>
      </Stack>
    </Flex>
  );
};

export default CommissionSetting;
