import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useWithdrawTransaction } from "hooks/business/transaction-report/useWithdrawTransaction";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { withdrawTransactionColumnsData } from "variables/columns/transaction-report/withdrawTransactionReportColumnsData";
import { WITHDRAW_TRANSACTION_SEARCH_OPTIONS } from "variables/options";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";

import ApproveWithdrawBlockAutoTransactionModal from "./components/ApproveWithdrawBlockAutoTransactionModal";
import ManualWithdrawTransactionModal from "./components/ManualWithdrawTransactionModal";
import RefreshWithdrawErrorTransactionModal from "./components/RefreshWithdrawErrorTransactionModal";
import RejectWithdrawTransactionModal from "./components/RejectWithdrawTransactionModal";
import UpdateWithdrawTransactionStatusModal from "./components/UpdateWithdrawTransactionStatusModal";

const WithdrawTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleBlockAutoTransaction,
    handleRefreshErrorTransaction,
  } = useWithdrawTransaction(role);

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  const [modalState, setModalState] = useState({
    approveWithdrawBlockAutoTransactionModalOpen: false,
    refreshWithdrawErrorTransactionModalOpen: false,
    rejectWithdrawTransactionModalOpen: false,
    manualWithdrawTransactionModalOpen: false,
    updateWithdrawTransactionStatusModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" mb="24px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column" justifyContent="center">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                เช็คข้อมูลถอน
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Box>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.WITHDRAW_TRANSACTION_REPORT}
                  columnsData={withdrawTransactionColumnsData(handleModalOpen)}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                  showSearchableDropdown={true}
                  onChange={handleOptionChange}
                  searchOptions={WITHDRAW_TRANSACTION_SEARCH_OPTIONS}
                />
              </QueryClientProvider>
            </CardBody>
          </Animated>
        </Card>
        <ApproveWithdrawBlockAutoTransactionModal
          isOpen={modalState.approveWithdrawBlockAutoTransactionModalOpen}
          onClose={() =>
            handleModalClose("approveWithdrawBlockAutoTransactionModal")
          }
          transaction={selectedTransaction}
          handleSubmit={handleBlockAutoTransaction}
        />
        <RefreshWithdrawErrorTransactionModal
          isOpen={modalState.refreshWithdrawErrorTransactionModalOpen}
          onClose={() =>
            handleModalClose("refreshWithdrawErrorTransactionModal")
          }
          transaction={selectedTransaction}
          handleSubmit={handleRefreshErrorTransaction}
        />
        <RejectWithdrawTransactionModal
          isOpen={modalState.rejectWithdrawTransactionModalOpen}
          onClose={() => handleModalClose("rejectWithdrawTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleBlockAutoTransaction}
        />
        <ManualWithdrawTransactionModal
          isOpen={modalState.manualWithdrawTransactionModalOpen}
          onClose={() => handleModalClose("manualWithdrawTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleBlockAutoTransaction}
        />
        <UpdateWithdrawTransactionStatusModal
          isOpen={modalState.updateWithdrawTransactionStatusModalOpen}
          onClose={() =>
            handleModalClose("updateWithdrawTransactionStatusModal")
          }
          transaction={selectedTransaction}
          handleSubmit={handleSaveTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default WithdrawTransactionReport;
