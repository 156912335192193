import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useStaffSetting } from "hooks/business/system-setting/useStaffSetting";
import React from "react";
import { validateField } from "utils/CommonUtils";
import { MARKETING_ROLE } from "variables/system";

const ManageMarketingModal = ({ isOpen, onClose, marketing = null }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "xl" });
  const { handleSaveStaff } = useStaffSetting();

  const onSubmit = (values, staff, actions) => {
    handleSaveStaff(values, staff, actions, true, onClose);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              เพิ่ม / แก้ไข การตลาด
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Text
            fontSize="16px"
            fontWeight="bold"
            pt={4}
            pb={4}
            color="green.500"
          >
            ข้อมูลการตลาด
          </Text>
          <Formik
            initialValues={{
              username: marketing?.username || "",
              password: marketing?.password || "",
              tel: marketing?.tel || "",
              fullName: marketing?.fullName || "",
              limitFlag: 0,
              limit: "",
              active: marketing?.active || 0,
              roleCode: MARKETING_ROLE,
              lineId: marketing?.lineId || "",
            }}
            onSubmit={(values, actions) => {
              onSubmit(values, marketing, actions);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="fullName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.fullName && form.touched.fullName
                          }
                          isRequired
                        >
                          <FormLabel>ชื่อ-นามสกุล</FormLabel>
                          <Input {...field} placeholder="ชื่อ-นามสกุล" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="username" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.username && form.touched.username
                          }
                          isRequired={!marketing}
                        >
                          <FormLabel>ยูสเซอร์เนม</FormLabel>
                          <Input
                            {...field}
                            placeholder="ยูสเซอร์เนม"
                            readOnly={!!marketing}
                            backgroundColor={marketing ? "#373F48" : ""}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field
                      name="password"
                      validate={(value) => validateField(value)}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired
                        >
                          <FormLabel>พาสเวิร์ด</FormLabel>
                          <Input
                            {...field}
                            placeholder="พาสเวิร์ด"
                            type="password"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="tel">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>เบอร์โทรศัพท์</FormLabel>
                          <Input {...field} placeholder="เบอร์โทรศัพท์" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="lineId">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>LINE ID</FormLabel>
                          <Input {...field} placeholder="@asgbet" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="roleCode" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.roleCode && form.touched.roleCode
                          }
                          d
                        >
                          <FormLabel>ตำแหน่ง</FormLabel>
                          <Input
                            {...field}
                            isReadOnly
                            backgroundColor="#373F48"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="active">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Flex>
                            <Switch
                              id="is-active"
                              {...field}
                              isChecked={field.value === 1}
                              onChange={() =>
                                form.setFieldValue(
                                  "active",
                                  field.value === 1 ? 0 : 1,
                                )
                              }
                            />
                            <FormLabel
                              color="#8C9592"
                              htmlFor="is-active"
                              mb="0"
                              fontSize="16px"
                              ml={4}
                            >
                              Active
                            </FormLabel>
                          </Flex>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mb={6} mt={2}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                    fontSize="14px"
                    fontWeight="normal"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageMarketingModal;
