import { Flex, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { fetchThieve } from "services/thief/thiefService";
import { thiefColumnsData } from "variables/columns/theif/thiefColumnsData";

const Thief = ({ pageName }) => {
  const queryClient = new QueryClient();

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานมิจฉาชีพ
                </Text>
              </Flex>
            </CardHeader>
            <CardBody pl="22px" pr="22px">
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  columnsData={thiefColumnsData}
                  query={"thieve"}
                  fetchData={fetchThieve}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </Animated>
      </Stack>
    </Flex>
  );
};

export default Thief;
