/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { SimpleHSeparator } from "components/Separator/Separator";
import { SidebarContext } from "contexts/SidebarContext";
import React from "react";
import { FaCircle } from "react-icons/fa";
import { NavLink, useHistory, useLocation } from "react-router-dom";

function Sidebar(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const { sidebarWidth, setSidebarWidth, toggleSidebar } =
    React.useContext(SidebarContext);
  console.log(toggleSidebar);
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const history = useHistory();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { sidebarVariant } = props;
    // Chakra Color Mode
    let activeBg = useColorModeValue("teal.300", "tg.300");
    let activeAccordionBg = useColorModeValue("white", "transparent");
    let inactiveBg = useColorModeValue("white", "gray.700");
    let inactiveColorIcon = useColorModeValue("green.500", "green.500");
    let activeColorIcon = useColorModeValue("white", "white");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";
    // Here are all the props that may change depending on sidebar's state.(Opaque or transparent)
    if (sidebarVariant === "opaque") {
      activeBg = useColorModeValue("green.500", "transparent");
      inactiveBg = useColorModeValue("gray.100", "gray.600");
      activeColor = useColorModeValue("gray.700", "white");
      inactiveColor = useColorModeValue("gray.400", "white");
      sidebarActiveShadow = "none";
    }
    return (
      <Accordion allowToggle>
        {routes.map((prop, index) => {
          if (prop.category) {
            return (
              <>
                <Text
                  fontSize={sidebarWidth === 280 ? "md" : "xs"}
                  color={activeColor}
                  fontWeight="bold"
                  mx="auto"
                  ps={{
                    sm: "10px",
                    xl: "16px",
                  }}
                  py="12px"
                  key={index}
                >
                  {prop.name}
                </Text>
                {createLinks(prop.items)}
              </>
            );
          }
          if (prop.collapse) {
            return (
              <AccordionItem border="none">
                <AccordionButton
                  display="flex"
                  align="center"
                  justify="center"
                  background={
                    activeRoute(prop.path) && prop.icon
                      ? "linear-gradient(90deg, #28AE95 0%, #0E7461 100%);"
                      : null
                  }
                  borderRadius="33px"
                  boxShadow={
                    activeRoute(prop.path) && prop.icon
                      ? sidebarActiveShadow
                      : null
                  }
                  _hover={{
                    boxShadow:
                      activeRoute(prop.path) && prop.icon
                        ? sidebarActiveShadow
                        : null,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                  w={sidebarWidth === 280 ? "100%" : "77%"}
                  px="15px"
                  py="12px"
                >
                  {activeRoute(prop.path) ? ( // active collapse
                    <Button
                      boxSize="initial"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="transparent"
                      transition={variantChange}
                      mx={{
                        xl: "auto",
                      }}
                      px="0px"
                      borderRadius="15px"
                      w="100%"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                        border: "none",
                      }}
                      _focus={{
                        transform: "none",
                        borderColor: "transparent",
                        border: "none",
                      }}
                    >
                      {prop.icon ? (
                        <Flex>
                          <IconBox
                            bg={activeBg}
                            color={activeColorIcon}
                            h="24px"
                            w="24px"
                            me="12px"
                            transition={variantChange}
                          >
                            <Box color="#fff">{prop.icon}</Box>
                          </IconBox>
                          <Text
                            color={activeColor}
                            my="auto"
                            fontSize="16px"
                            display={sidebarWidth === 280 ? "block" : "none"}
                          >
                            {prop.name}
                          </Text>
                        </Flex>
                      ) : (
                        <HStack
                          spacing={sidebarWidth === 280 ? "22px" : "0px"}
                          ps={sidebarWidth === 280 ? "10px" : "0px"}
                          ms={sidebarWidth === 280 ? "0px" : "8px"}
                        >
                          <Icon
                            as={FaCircle}
                            w="10px"
                            color="teal.300"
                            display={sidebarWidth === 280 ? "block" : "none"}
                          />
                          <Text color={activeColor} my="auto" fontSize="14px">
                            {sidebarWidth === 280 ? prop.name : prop.name[0]}
                          </Text>
                        </HStack>
                      )}
                    </Button>
                  ) : (
                    // in-active collapse
                    <Button
                      boxSize="initial"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="transparent"
                      mx={{
                        xl: "auto",
                      }}
                      px="0px"
                      borderRadius="15px"
                      w="100%"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        borderColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      {prop.icon ? (
                        <Flex>
                          <IconBox
                            bg={activeBg}
                            color={activeColorIcon}
                            h="24px"
                            w="24px"
                            me="12px"
                            transition={variantChange}
                            boxShadow={sidebarActiveShadow}
                            _hover={{ boxShadow: sidebarActiveShadow }}
                          >
                            <Box color="green.500">{prop.icon}</Box>
                          </IconBox>
                          <Text
                            color="#8C9592"
                            my="auto"
                            fontSize="16px"
                            display={sidebarWidth === 280 ? "block" : "none"}
                            fontWeight="normal"
                          >
                            {prop.name}
                          </Text>
                        </Flex>
                      ) : (
                        <HStack
                          spacing={sidebarWidth === 280 ? "26px" : "0px"}
                          ps={sidebarWidth === 280 ? "10px" : "0px"}
                          ms={sidebarWidth === 280 ? "0px" : "8px"}
                        >
                          <Icon
                            as={FaCircle}
                            w="6px"
                            color="teal.300"
                            display={sidebarWidth === 280 ? "block" : "none"}
                          />
                          <Text
                            color={inactiveColor}
                            my="auto"
                            fontSize="md"
                            fontWeight="normal"
                          >
                            {sidebarWidth === 280 ? prop.name : prop.name[0]}
                          </Text>
                        </HStack>
                      )}
                    </Button>
                  )}
                  <AccordionIcon
                    color="gray.400"
                    display={
                      prop.icon
                        ? sidebarWidth === 280
                          ? "block"
                          : "none"
                        : "block"
                    }
                    transform={
                      prop.icon
                        ? null
                        : sidebarWidth === 280
                        ? null
                        : "translateX(-70%)"
                    }
                  />
                </AccordionButton>
                <AccordionPanel
                  pe={prop.icon ? null : "0px"}
                  pb="8px"
                  ps={prop.icon ? null : sidebarWidth === 280 ? null : "8px"}
                >
                  <List>
                    {
                      prop.icon
                        ? createLinks(prop.items) // for bullet accordion links
                        : createAccordionLinks(prop.items) // for non-bullet accordion links
                    }
                  </List>
                </AccordionPanel>
              </AccordionItem>
            );
          } else {
            return (
              <NavLink to={prop.layout + prop.path}>
                {prop.icon ? (
                  <Box
                    bg={
                      activeRoute(prop.path) && prop.icon
                        ? activeAccordionBg
                        : "transparent"
                    }
                  >
                    <HStack
                      spacing="14px"
                      py="12px"
                      px="15px"
                      background={
                        activeRoute(prop.path) && prop.icon
                          ? "linear-gradient(90deg, #28AE95 0%, #0E7461 100%);"
                          : "transparent"
                      }
                      borderRadius="33px"
                    >
                      <IconBox
                        bg="transparent"
                        h="24px"
                        w="24px"
                        transition={variantChange}
                      >
                        <Box
                          color={activeRoute(prop.path) ? "#fff" : "green.500"}
                        >
                          {prop.icon}
                        </Box>
                      </IconBox>
                      <Text
                        color={activeRoute(prop.path) ? "#fff" : "#8C9592"}
                        fontWeight={activeRoute(prop.path) ? "bold" : "normal"}
                        fontSize="16px"
                        display={sidebarWidth === 280 ? "block" : "none"}
                      >
                        {prop.name}
                      </Text>
                    </HStack>
                  </Box>
                ) : (
                  <ListItem>
                    <HStack
                      spacing={
                        sidebarWidth === 280
                          ? activeRoute(prop.path.toLowerCase())
                            ? "20px"
                            : "20px"
                          : "8px"
                      }
                      py="5px"
                      px={sidebarWidth === 280 ? "28px" : "0px"}
                    >
                      <Icon
                        as={FaCircle}
                        w={activeRoute(prop.path.toLowerCase()) ? "6px" : "6px"}
                        color="green.500"
                        // display={sidebarWidth === 280 ? "block" : "none"}
                      />
                      <Text
                        color={
                          activeRoute(prop.path.toLowerCase())
                            ? "green.500"
                            : "#8C9592"
                        }
                        fontWeight="normal"
                        fontSize="14px"
                        display={sidebarWidth === 280 ? "block" : "none"}
                      >
                        {/*{sidebarWidth === 280 ? prop.name : prop.name[0]}*/}
                        {prop.name}
                      </Text>
                    </HStack>
                  </ListItem>
                )}
              </NavLink>
            );
          }
        })}
      </Accordion>
    );
  };

  const createAccordionLinks = (routes) => {
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    let activeColor = useColorModeValue("gray.700", "white");
    return routes.map((prop, index) => {
      return (
        <NavLink to={prop.layout + prop.path}>
          <ListItem
            pt="5px"
            ms={sidebarWidth === 280 ? "26px" : "0px"}
            key={index}
          >
            <Text
              mb="4px"
              color={
                activeRoute(prop.path.toLowerCase())
                  ? activeColor
                  : inactiveColor
              }
              fontWeight={
                activeRoute(prop.path.toLowerCase()) ? "bold" : "normal"
              }
              fontSize="14px"
            >
              {sidebarWidth === 280 ? prop.name : prop.name[0]}
            </Text>
          </ListItem>
        </NavLink>
      );
    });
  };
  const { logoText, routes, sidebarVariant, username, profileUrl } = props;
  let isWindows = navigator.platform.startsWith("Win");
  let links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.600");
    sidebarRadius = "16px";
    sidebarMargins = "85px 0px 20px 20px !important";
  }
  let brand = (
    <Box pt={sidebarWidth === 280 ? "23px" : "40px"} mb="12px">
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        mb="28px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <Text
          fontSize="md"
          mt="3px"
          display={sidebarWidth === 280 ? "block" : "none"}
        >
          {logoText}
        </Text>
      </Link>
      <SimpleHSeparator />
    </Box>
  );

  let profile = (
    <Box display="flex" justifyContent="center" pt="35px">
      <Link
        display="flex"
        lineHeight="100%"
        fontWeight="normal"
        alignItems="center"
        direction="column"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Avatar
            src={profileUrl}
            height="84px"
            width="84px"
            mb="20px"
            position="relative"
          >
            <Box
              position="absolute"
              bottom="-5px"
              left="50%"
              transform="translateX(-50%)"
              bg="green.500"
              boxSize="1.5em"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="white"
              fontSize="12px"
              fontWeight="normal"
              w="52px"
              h="17px"
            >
              Online
            </Box>
          </Avatar>
          <Text
            fontSize="md"
            mt="8px"
            textAlign="center"
            fontWeight="bold"
            color="#fff"
          >
            {username}
          </Text>
          <Flex mt="8px" gap="8px" mt="24px" mb="12px">
            <Button
              size="sm"
              color="green.500"
              borderColor="green.500"
              borderRadius="26px"
              variant="outline"
              pl="22px"
              pr="22px"
              fontSize="12px"
              fontWeight="normal"
              onClick={() => history.push("/admin/profile")}
            >
              ข้อมูลส่วนตัว
            </Button>
            <Button
              size="sm"
              color="green.500"
              borderColor="green.500"
              variant="outline"
              borderRadius="26px"
              pl="22px"
              pr="22px"
              fontSize="12px"
              fontWeight="normal"
              onClick={() => history.push("/auth/authentication/sign-in/basic")}
            >
              ออกจากระบบ
            </Button>
          </Flex>
        </Box>
      </Link>
    </Box>
  );

  let sidebarContent = (
    <Box>
      {/*<Box mb="20px">{brand}</Box>*/}
      <Box mb="20px">{profile}</Box>
      <Stack direction="column" background="gray.700" borderRadius="16px">
        <Text
          fontWeight="bold"
          fontSize="12px"
          pt="24px"
          pb="8px"
          pl="25px"
          pr="25px"
        >
          เมนูหลัก
        </Text>
        <Box pb="24px" pl="22px" pr="22px">
          {links}
        </Box>
      </Stack>
      {/*<SidebarHelp sidebarWidth={sidebarWidth}/>*/}
    </Box>
  );

  // SIDEBAR
  return (
    <Box
      ref={mainPanel}
      onMouseEnter={
        toggleSidebar
          ? () => setSidebarWidth(sidebarWidth === 80 ? 280 : 80)
          : null
      }
      onMouseLeave={
        toggleSidebar
          ? () => setSidebarWidth(sidebarWidth === 280 ? 80 : 280)
          : null
      }
    >
      <Box display={{ sm: "none", xl: "block" }} position="absolute">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w={`${sidebarWidth}px`}
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <Box id="sidebarScrollRemove" overflowY="scroll">
            {sidebarContent}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

// FUNCTIONS

export function SidebarResponsive(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { sidebarVariant } = props;
    // Chakra Color Mode
    let activeBg = useColorModeValue("teal.300", "transparent");
    let activeAccordionBg = useColorModeValue("white", "gray.700");
    let inactiveBg = useColorModeValue("white", "gray.700");
    let inactiveColorIcon = useColorModeValue("teal.300", "white");
    let activeColorIcon = useColorModeValue("white", "white");
    let activeColor = useColorModeValue("gray.700", "white");
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    // Here are all the props that may change depending on sidebar's state.(Opaque or transparent)
    if (sidebarVariant === "opaque") {
      inactiveBg = useColorModeValue("gray.100", "gray.600");
      activeColor = useColorModeValue("gray.700", "white");
      inactiveColor = useColorModeValue("gray.400", "gray.400");
    }
    return routes.map((prop, index) => {
      if (prop.category) {
        return (
          <>
            <Text
              fontSize={"sm"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              key={index}
            >
              {prop.name}
            </Text>
            {createLinks(prop.items)}
          </>
        );
      }
      if (prop.collapse) {
        return (
          <Accordion allowToggle>
            <AccordionItem border="none">
              <AccordionButton
                display="flex"
                align="center"
                justify="center"
                key={index}
                borderRadius="15px"
                _focus={{ boxShadow: "none" }}
                _hover={{ boxShadow: "none" }}
                px={prop.icon ? null : "0px"}
                py={prop.icon ? "12px" : null}
                bg={
                  activeRoute(prop.path) && prop.icon
                    ? activeAccordionBg
                    : "transparent"
                }
              >
                {activeRoute(prop.path) ? (
                  <Button
                    boxSize="initial"
                    justifyContent="flex-start"
                    alignItems="center"
                    bg="transparent"
                    transition={variantChange}
                    mx={{
                      xl: "auto",
                    }}
                    px="0px"
                    borderRadius="15px"
                    _hover="none"
                    w="100%"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                  >
                    {prop.icon ? (
                      <Flex>
                        <IconBox
                          bg={activeBg}
                          color={activeColorIcon}
                          h="15px"
                          w="15px"
                          me="12px"
                          transition={variantChange}
                        >
                          {prop.icon}
                        </IconBox>
                        <Text
                          color={activeColor}
                          my="auto"
                          fontSize="14px"
                          display={"block"}
                        >
                          {prop.name}
                        </Text>
                      </Flex>
                    ) : (
                      <HStack spacing={"22px"} ps="10px" ms="0px">
                        <Icon as={FaCircle} w="10px" color="teal.300" />
                        <Text color={activeColor} my="auto" fontSize="14px">
                          {prop.name}
                        </Text>
                      </HStack>
                    )}
                  </Button>
                ) : (
                  <Button
                    boxSize="initial"
                    justifyContent="flex-start"
                    alignItems="center"
                    bg="transparent"
                    mx={{
                      xl: "auto",
                    }}
                    px="0px"
                    borderRadius="15px"
                    _hover="none"
                    w="100%"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    {prop.icon ? (
                      <Flex>
                        <IconBox
                          bg={inactiveBg}
                          color={inactiveColorIcon}
                          h="15px"
                          w="15px"
                          me="12px"
                          transition={variantChange}
                        >
                          {prop.icon}
                        </IconBox>
                        <Text
                          color={inactiveColor}
                          my="auto"
                          fontSize="14px"
                          fontWeight="normal"
                        >
                          {prop.name}
                        </Text>
                      </Flex>
                    ) : (
                      <HStack spacing={"26px"} ps={"10px"} ms={"0px"}>
                        <Icon as={FaCircle} w="6px" color="teal.300" />
                        <Text
                          color={inactiveColor}
                          my="auto"
                          fontSize="14px"
                          fontWeight="normal"
                        >
                          {prop.name}
                        </Text>
                      </HStack>
                    )}
                  </Button>
                )}
                <AccordionIcon color="gray.400" />
              </AccordionButton>
              <AccordionPanel pe={prop.icon ? null : "0px"} pb="8px">
                <List>
                  {
                    prop.icon
                      ? createLinks(prop.items) // for bullet accordion links
                      : createAccordionLinks(prop.items) // for non-bullet accordion links
                  }
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      } else {
        return (
          <NavLink to={prop.layout + prop.path}>
            {prop.icon ? (
              <Box>
                <HStack spacing="14px" py="12px" px="15px">
                  <IconBox
                    bg="transparent"
                    color="white"
                    h="15px"
                    w="15px"
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                  <Text
                    color={
                      activeRoute(prop.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={activeRoute(prop.name) ? "bold" : "normal"}
                    fontSize="14px"
                  >
                    {prop.name}
                  </Text>
                </HStack>
              </Box>
            ) : (
              <ListItem>
                <HStack spacing="22px" py="5px" px="10px">
                  <Icon
                    as={FaCircle}
                    w={activeRoute(prop.path.toLowerCase()) ? "10px" : "6px"}
                    color="green.500"
                  />
                  <Text
                    fontSize="14px"
                    color={
                      activeRoute(prop.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(prop.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {prop.name}
                  </Text>
                </HStack>
              </ListItem>
            )}
          </NavLink>
        );
      }
    });
  };

  const createAccordionLinks = (routes) => {
    let inactiveColor = useColorModeValue("gray.400", "gray.400");
    let activeColor = useColorModeValue("gray.700", "white");
    return routes.map((prop, index) => {
      return (
        <NavLink to={prop.layout + prop.path}>
          <ListItem pt="5px" ms="26px" key={index}>
            <Text
              color={
                activeRoute(prop.path.toLowerCase())
                  ? activeColor
                  : inactiveColor
              }
              fontWeight={
                activeRoute(prop.path.toLowerCase()) ? "bold" : "normal"
              }
              fontSize="14px"
            >
              {prop.name}
            </Text>
          </ListItem>
        </NavLink>
      );
    });
  };
  const { logoText, routes, username, profileUrl } = props;

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let hamburgerColor = useColorModeValue("gray.500", "gray.200");
  if (props.secondary === true) {
    hamburgerColor = "white";
  }
  const brand = (
    <Box pt={"15px"} mb="12px">
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        mb="25px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <Text fontSize="md" mt="3px">
          {logoText}
        </Text>
      </Link>
      <SimpleHSeparator />
    </Box>
  );

  const profile = (
    <Box display="flex" justifyContent="flex-start" pl="20px">
      <Link
        href="#"
        display="flex"
        lineHeight="100%"
        fontWeight="normal"
        alignItems="center"
        fontSize="11px"
      >
        <Avatar src={profileUrl} height="40px" width="40px" mr="10px" />
        <Flex direction="column" alignItems="flex-start">
          <Text fontSize="md" mt="3px">
            {username}
          </Text>
          <Flex alignItems="center" mt="8px">
            <Box w="8px" h="8px" bg="green.500" borderRadius="50%" mr="6px" />
            <Text fontSize="14px" color="green.500">
              online
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );

  // SIDEBAR Responsive
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // Color variables
  return (
    <Box ref={mainPanel} display={props.display}>
      <Box display={{ sm: "block", xl: "none" }}>
        <>
          <HamburgerIcon
            color={hamburgerColor}
            w="18px"
            h="18px"
            me="16px"
            ref={btnRef}
            colorScheme="teal"
            cursor="pointer"
            onClick={onOpen}
          />
          <Drawer
            placement={
              document.documentElement.dir === "rtl" ? "right" : "left"
            }
            isOpen={isOpen}
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent w="250px" maxW="250px">
              <DrawerCloseButton
                _focus={{ boxShadow: "none" }}
                _hover={{ boxShadow: "none" }}
              />
              <DrawerBody maxW="250px" px="1rem">
                <Box maxW="100%" h="100vh">
                  {/*<Box mb="20px">{brand}</Box>*/}
                  {/*<Box mb="20px">{profile}</Box>*/}
                  <Stack direction="column" mb="40px">
                    <Box>{links}</Box>
                  </Stack>
                  {/*<SidebarHelp/>*/}
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Box>
    </Box>
  );
}

export default Sidebar;
