import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useMemberTag } from "hooks/business/member-management/useMemberTag";
import React, { useState } from "react";
import { memberTagColumnsData } from "variables/columns/member-management/memberTagColumnsData";

import DeleteMemberTagModal from "./components/DeleteMemberTagModal";
import ManageMemberTagModal from "./components/ManageMemberTagModal";

const MemberTag = ({ pageName }) => {
  const [modalState, setModalState] = useState({
    manageMemberTagModalOpen: false,
    deleteMemberTagModalOpen: false,
  });

  const [selectedTag, setSelectedTag] = useState(null);

  const handleModalOpen = (modal, tag = null) => {
    setSelectedTag(tag);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTag(null);
  };

  const { data, isLoading, handelSaveMemberTag, deleteTag, isDeleting } =
    useMemberTag();

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="48px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                จัดการแท็คสมาชิก
              </Text>
            </Flex>
          </CardHeader>
          <Flex justify="flex-end">
            <Button
              backgroundColor="green.500"
              fontSize="14px"
              fontWeight="normal"
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              width={{ base: "100%", md: "auto" }}
              onClick={() => handleModalOpen("manageMemberTagModal")}
            >
              เพิ่มแท็คใหม่
            </Button>
          </Flex>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <SimpleTable
                tableData={data}
                columnsData={memberTagColumnsData(handleModalOpen)}
                isLoading={isLoading}
              />
            </CardBody>
          </Animated>
        </Card>
        <ManageMemberTagModal
          isOpen={modalState.manageMemberTagModalOpen}
          onClose={() => handleModalClose("manageMemberTagModal")}
          tag={selectedTag}
          handleSubmit={handelSaveMemberTag}
        />
        <DeleteMemberTagModal
          isOpen={modalState.deleteMemberTagModalOpen}
          onClose={() => handleModalClose("deleteMemberTagModal")}
          tag={selectedTag}
          handleSubmit={deleteTag}
          isSubmitting={isDeleting}
        />
      </Stack>
    </Flex>
  );
};

export default MemberTag;
