import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import { usePopupSetting } from "hooks/business/website-setting/usePopupSetting";
import React, { useState } from "react";

import DeletePopupModal from "./components/DeletePopupModal";
import ManagePopupModal from "./components/ManagePopupModal";
import PopupCard from "./components/PopupCard";

const PopupSetting = ({ pageName }) => {
  const { popupData, handleSubmitPopup, handleDeletePopup, isDeleting } =
    usePopupSetting();
  const [selectedPopup, setSelectedPopup] = useState(null);

  const [modalState, setModalState] = useState({
    managePopupModalOpen: false,
    deletePopupModalOpen: false,
  });

  const handleModalOpen = (modal, popup = null) => {
    setSelectedPopup(popup);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPopup(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%" h="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text fontSize="24px" fontWeight="bold" color="green.500" mb="24px">
            {pageName}
          </Text>
        </Animated>
        <Card pb="64px">
          <CardHeader pb="32px" pt="12px">
            <Flex justifyContent="space-between" w="100%">
              <Text fontSize="18px" fontWeight="bold" color="#fff">
                จัดการตั้งค่าป๊อปอัพเว็บไซต์
              </Text>
              <Button
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "150px" }}
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                variant="solid"
                borderRadius="33px"
                fontSize="14px"
                fontWeight="normal"
                onClick={() => handleModalOpen("managePopupModal")}
              >
                <Icon as={CreateIcon} mr="5px" />
                เพิ่มป๊อปอัพ
              </Button>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={6}
              w="100%"
            >
              {popupData?.map((popup) => (
                <Box key={popup.id}>
                  <PopupCard
                    popup={popup}
                    onEdit={() => handleModalOpen("managePopupModal", popup)}
                    onDelete={() => handleModalOpen("deletePopupModal", popup)}
                  />
                </Box>
              ))}
            </SimpleGrid>
          </Animated>
        </Card>
        <ManagePopupModal
          isOpen={modalState.managePopupModalOpen}
          onClose={() => handleModalClose("managePopupModal")}
          popup={selectedPopup}
          handleSubmit={handleSubmitPopup}
        />
        <DeletePopupModal
          isOpen={modalState.deletePopupModalOpen}
          onClose={() => handleModalClose("deletePopupModal")}
          popup={selectedPopup}
          handleSubmit={handleDeletePopup}
          isSubmitting={isDeleting}
        />
      </Stack>
    </Flex>
  );
};

export default PopupSetting;
