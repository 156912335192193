import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SearchTable1 from "components/Tables/SearchTable1";
import { useCommissionReport } from "hooks/business/member-report/useCommissionReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { commissionReportColumnsData } from "variables/columns/member-report/commissionReportColumnsData";

const CommissionReport = ({ pageName }) => {
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    true,
    "",
  );

  const { data: commissionReportData, isLoading } = useCommissionReport(search);

  const searchOptions = [
    {
      label: "ทั้งหมด",
      value: "",
    },
    {
      label: "รับพ๊อยต์",
      value: "EARN_POINT",
    },
    {
      label: "ค่าคอมมิชชั่น",
      value: "COMM_CREDIT",
    },
    {
      label: "แลกพ๊อยต์",
      value: "TRANS_CREDIT",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      type: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" mb="24px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                รายงานคอมมิชชั่น
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Box>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <Flex direction="column" align="flex-end" mb="24px" w="100%">
                <Box width="100%">
                  <SearchTable1
                    isLoading={isLoading}
                    columnsData={commissionReportColumnsData()}
                    tableData={commissionReportData}
                    showSearchableDropdown={true}
                    onChange={handleOptionChange}
                    searchOptions={searchOptions}
                  />
                </Box>
              </Flex>
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default CommissionReport;
