import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ColorPicker from "components/ColorPicker/ColorPicker";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";

const ManageMemberTagModal = ({ isOpen, onClose, tag, handleSubmit }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const COLOR_OPTIONS = [
    "#718096",
    "#E53E3E",
    "#DD6B20",
    "#D69E2E",
    "#53b199",
    "#319795",
    "#3182CE",
    "#00B5D8",
    "#805AD5",
    "#D53F8C",
  ];

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          backgroundColor="gray.800"
          borderTopRadius="12px"
          borderBottom="1px solid rgba(55, 63, 72, 1)"
          mb={4}
        >
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              เพิ่ม / แก้ไข แท็ก
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              id: tag?.id || null,
              color: tag?.color || "rgba(29,215,96,1)",
              name: tag?.name || "",
              description: tag?.description || "",
              lang: tag?.lang || "",
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Field name="name" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      isRequired
                    >
                      <FormLabel>ชื่อแท็ค</FormLabel>
                      <Input {...field} placeholder="ชื่อ" />
                    </FormControl>
                  )}
                </Field>
                <Field name="description" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                      isRequired
                    >
                      <FormLabel>รายละเอียดแท็ค</FormLabel>
                      <Input {...field} placeholder="รายละเอียด" />
                    </FormControl>
                  )}
                </Field>
                <FormControl mt={4}>
                  <FormLabel>สีแท็ค</FormLabel>
                  <ColorPicker
                    colors={COLOR_OPTIONS}
                    initialColor={props.initialValues.color}
                    fieldName="color"
                  />
                </FormControl>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    fontSize="14px"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageMemberTagModal;
