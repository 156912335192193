import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField, validateObject } from "utils/CommonUtils";

const ManageStaffModal = ({
  isOpen,
  onClose,
  staff = null,
  staffRolesData,
  handleSubmit,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const modalSize = useBreakpointValue({ base: "full", md: "xl" });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              เพิ่ม / แก้ไข พนักงาน
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Text
            fontSize="16px"
            fontWeight="bold"
            pt={4}
            pb={4}
            color="green.500"
          >
            ข้อมูลพนักงาน
          </Text>
          <Formik
            initialValues={{
              username: staff?.username || "",
              password: staff?.password || "",
              tel: staff?.tel || "",
              fullName: staff?.fullName || "",
              limitFlag: staff?.limitFlag || 0,
              limit: staff?.limit || "",
              active: staff?.active || 0,
              roleCode: staff?.role || "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, staff, actions, false, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={2}>
                    <Field name="fullName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.fullName && form.touched.fullName
                          }
                          isRequired
                        >
                          <FormLabel>ชื่อ-นามสกุล</FormLabel>
                          <Input {...field} placeholder="ชื่อ-นามสกุล" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="username" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.username && form.touched.username
                          }
                          isRequired={!staff}
                        >
                          <FormLabel>ยูสเซอร์เนม</FormLabel>
                          <Input
                            {...field}
                            placeholder="ยูสเซอร์เนม"
                            readOnly={!!staff}
                            backgroundColor={
                              staff ? "#373F48" : "rgba(30, 37, 44, 1)"
                            }
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field
                      name="password"
                      validate={(value) => validateObject(value, staff)}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired={!staff}
                        >
                          <FormLabel>พาสเวิร์ด</FormLabel>
                          <Input
                            {...field}
                            placeholder="พาสเวิร์ด"
                            type="password"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="tel">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>เบอร์โทรศัพท์</FormLabel>
                          <Input {...field} placeholder="เบอร์โทรศัพท์" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="roleCode" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.roleCode && form.touched.roleCode
                          }
                          isRequired
                        >
                          <FormLabel>ตำแหน่ง</FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกตำแหน่ง"
                            fontSize="sm"
                            borderRadius="33px"
                          >
                            {staffRolesData?.map((roles) => (
                              <option key={roles.id} value={roles.roleName}>
                                {roles.roleName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={1} mt={4}>
                    <Field name="limitFlag">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Flex>
                            <Switch
                              id="limit-flag"
                              {...field}
                              isChecked={field.value === 1}
                              onChange={() =>
                                form.setFieldValue(
                                  "limitFlag",
                                  field.value === 1 ? 0 : 1,
                                )
                              }
                            />
                            <FormLabel
                              htmlFor="limit-flag"
                              mb="0"
                              color="#8C9592"
                              fontSize="16px"
                              ml={6}
                            >
                              ตั้งค่าลิมิต
                            </FormLabel>
                          </Flex>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={1} mt={4}>
                    <Field name="active">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Flex>
                            <Switch
                              id="is-active"
                              {...field}
                              isChecked={field.value === 1}
                              onChange={() =>
                                form.setFieldValue(
                                  "active",
                                  field.value === 1 ? 0 : 1,
                                )
                              }
                            />
                            <FormLabel
                              htmlFor="is-active"
                              mb="0"
                              color="#8C9592"
                              fontSize="16px"
                              ml={6}
                            >
                              Active
                            </FormLabel>
                          </Flex>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  {props.values.limitFlag === 1 && (
                    <GridItem colSpan={{ base: 2, md: 2 }}>
                      <Field name="limit" validate={validateField}>
                        {({ field }) => (
                          <FormControl>
                            <Stack>
                              <FormLabel>ลิมิตต่อวัน</FormLabel>
                              <Input
                                {...field}
                                placeholder="จำนวนลิมิตต่อวัน"
                                type="number"
                                fontSize="sm"
                              />
                            </Stack>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                    fontSize="14px"
                    fontWeight="normal"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageStaffModal;
