import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SimpleTable from "components/Tables/SimpleTable";
import { useMarketingReport } from "hooks/business/website-report/useMarketingReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { marketingReportColumnsData } from "variables/columns/website-report/marketingReportColumnsData";

const MarketingReport = ({ pageName }) => {
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    data: marketingReportData,
    isLoading,
    downloadableData,
  } = useMarketingReport(search);

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="38px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                สรุป กำไร-ขาดทุน (การตลาด)
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Box>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.5s">
            <CardBody>
              <SimpleTable
                tableData={marketingReportData}
                columnsData={marketingReportColumnsData}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
                isLoading={isLoading}
              />
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default MarketingReport;
