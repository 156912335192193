import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { commissionSettingColumnsData } from "variables/columns/system-setting/commissionSettingColumnsData";

import CommissionSettingModal from "./ComissionSettingModal";

const CommissionForm = ({
  title,
  initialValues,
  handleSubmit,
  isCommissionDataLoading,
  isSaving,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState(null);
  const [modalIndex, setModalIndex] = useState(null);

  const isLoading = isCommissionDataLoading;

  const openModal = (data, index) => {
    setModalData(data);
    setModalIndex(index);
    onOpen();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const handleModalSubmit = (updatedData) => {
          setFieldValue(`commissions.${modalIndex}.setting`, updatedData);
          onClose();
        };

        const tableData = values.commissions?.map((commission, index) => ({
          ...commission,
          index,
        }));

        return (
          <Form>
            <Flex direction="column" w="100%" pb={6}>
              <Text fontSize="18px" fontWeight="bold" mb="4" color="#fff">
                ตั้งค่าคอมมิชชั่น{" "}
                <Box as="span" color="green.500" fontWeight="bold">
                  {title}
                </Box>
              </Text>
              <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                <FieldArray name="commissions" enableReinitialize>
                  {() => (
                    <SimpleTable
                      columnsData={commissionSettingColumnsData(openModal)}
                      tableData={tableData}
                      rowSize={tableData?.length}
                      isLoading={isLoading}
                    />
                  )}
                </FieldArray>
              </Animated>
              <Flex justifyContent="flex-start" width="100%" mt={6}>
                <Button
                  type="submit"
                  background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                  width={{ base: "100%", md: "auto" }}
                  minW={{ base: "none", md: "195px" }}
                  fontWeight="normal"
                  fontSize="14px"
                  isLoading={isSaving}
                >
                  บันทึกข้อมูล
                </Button>
              </Flex>
            </Flex>
            {modalData && (
              <CommissionSettingModal
                isOpen={isOpen}
                onClose={onClose}
                modalData={modalData}
                handleSubmit={handleModalSubmit}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CommissionForm;
