import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useSpinnerReport } from "hooks/business/website-report/useSpinnerReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { spinnerReportColumnsData } from "variables/columns/website-report/spinnerReportColumnsData";
import { QueryKeys } from "variables/queryKeys";

const SpinnerReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { fetchData, downloadableData } = useSpinnerReport();
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" mb="24px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column" justifyContent="center">
              <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                ประวัติการการหมุนวงล้อ
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Box>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.SPINNER_REPORT}
                  columnsData={spinnerReportColumnsData}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                />
              </QueryClientProvider>
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default SpinnerReport;
