import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { useHistory } from "react-router-dom";
import { useSortBy, useTable } from "react-table";

import "../../assets/css/styles.css";
import FileExportDropdown from "../FileExportDropdown/FileExportDropdown";
import { NotFoundIcon } from "../Icons/Icons";

function SimpleTable(props) {
  const {
    columnsData,
    tableData,
    rowSize,
    showExport,
    downloadableData,
    filename,
    showAction = false,
    actionButtonText,
    actionButtonIcon,
    navigationUrl,
    isLoading,
  } = props;
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => {
    return tableData && tableData?.length > 0
      ? tableData?.slice(0, rowSize)
      : [];
  }, [tableData, rowSize]);

  const history = useHistory();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  return (
    <>
      <Flex direction="column" w="100%" h="100%">
        <Flex justify="flex-end" align="center" w="100%" pb="22px" pt="6px">
          {showExport && (
            <FileExportDropdown data={downloadableData} filename={filename} />
          )}
          {showAction && (
            <Button
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              color="#fff"
              mr="8px"
              borderRadius="33px"
              fontSize="14px"
              pl={6}
              pr={6}
              onClick={() => history.push(navigationUrl)}
            >
              {actionButtonIcon != null && (
                <Icon
                  as={actionButtonIcon}
                  mr="5px"
                  h="24px"
                  w="24px"
                  justifyContent="center"
                />
              )}
              {actionButtonText}
            </Button>
          )}
        </Flex>
        <Box
          overflowX="auto"
          w="100%"
          h="100%"
          borderRadius="6px"
          border="1px solid rgba(55, 63, 72, 1)"
          bg={isLoading || tableData?.length === 0 ? "rgba(33, 39, 46, 1)" : ""}
        >
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            w="100%"
          >
            <Thead backgroundColor="#1D2229">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      pe="0px"
                      pt={3}
                      pb={3}
                      pl={4}
                      pr={4}
                      fontFamily="'LINESeedSansTH'"
                      fontWeight="normal"
                      fontSize="14px"
                      whiteSpace="nowrap"
                      textAlign={column.textAlign || "center"}
                      width={column.width || "auto"}
                    >
                      <Flex
                        justify={column.justifyContent || "center"}
                        fontSize={{ sm: "10px", lg: "14px" }}
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <Icon
                            w={{ sm: "10px", md: "14px" }}
                            h={{ sm: "10px", md: "14px" }}
                            color={column.isSorted ? "gray.500" : "gray.400"}
                            float="right"
                            as={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? TiArrowSortedDown
                                  : TiArrowSortedUp
                                : TiArrowUnsorted
                            }
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {isLoading ? (
                <Tr>
                  <Td
                    colSpan={headerGroups[0].headers.length}
                    textAlign="center"
                    fontSize="14px"
                    color="gray.500"
                    borderBottom="none"
                    h="425px"
                    p={4}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt="8px"
                      mb="8px"
                    >
                      <span className="loader"></span>
                    </Box>
                  </Td>
                </Tr>
              ) : tableData?.length === 0 ? (
                <Tr>
                  <Td
                    colSpan={headerGroups[0].headers.length}
                    textAlign="center"
                    fontSize="14px"
                    color="gray.500"
                    borderBottom="none"
                    h="425px"
                    p={4}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt="8px"
                      mb="8px"
                    >
                      <NotFoundIcon
                        w="80px"
                        h="80px"
                        color="rgba(55, 63, 72, 1)"
                      />
                    </Box>
                    ไม่มีข้อมูลสำหรับรายการนี้
                  </Td>
                </Tr>
              ) : (
                rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            fontSize="sm"
                            key={index}
                            color="gray.500"
                            pt={2}
                            pb={2}
                            pl={4}
                            pr={4}
                            className="td-border-bottom"
                            whiteSpace="nowrap"
                            textAlign={cell.column.textAlign || "center"}
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </>
  );
}

export default SimpleTable;
