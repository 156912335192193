import { Box, Flex, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { Loader } from "components/Loader/Loader";
import { usePaymentGatewaySetting } from "hooks/business/finance-setting/usePaymentGatewaySetting";
import React, { useState } from "react";

import PaymentGatewayCard from "./components/PaymentGatewayCard";
import UpdatePaymentGatewaySettingModal from "./components/UpdatePaymentGatewaySettingModal";

const PaymentGatewaySetting = ({ pageName }) => {
  const { paymentGatewayData, isLoading, handleUpdate } =
    usePaymentGatewaySetting();

  const [modalState, setModalState] = useState({
    updatePaymentGatewayModalOpen: false,
  });

  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);

  const handleModalOpen = (modal, paymentGateway = null) => {
    setSelectedPaymentGateway(paymentGateway);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPaymentGateway(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="64px">
          <CardHeader pb="32px" pt="12px">
            <Flex direction="column">
              <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                ตั้งค่า Payment Gateway
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
            {isLoading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="400px"
                w="100%"
              >
                <Loader />
              </Flex>
            ) : (
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing={6}
                w="100%"
              >
                {paymentGatewayData
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((paymentGateway) => (
                    <Box key={paymentGateway.id}>
                      <PaymentGatewayCard
                        paymentGateway={paymentGateway}
                        onEdit={() =>
                          handleModalOpen(
                            "updatePaymentGatewayModal",
                            paymentGateway,
                          )
                        }
                      />
                    </Box>
                  ))}
              </SimpleGrid>
            )}
          </Animated>
        </Card>
        <UpdatePaymentGatewaySettingModal
          isOpen={modalState.updatePaymentGatewayModalOpen}
          onClose={() => handleModalClose("updatePaymentGatewayModal")}
          paymentGateway={selectedPaymentGateway}
          handleSubmit={handleUpdate}
        />
      </Stack>
    </Flex>
  );
};

export default PaymentGatewaySetting;
