import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Form, Formik } from "formik";
import React from "react";
import { DEVICE_OPTIONS } from "variables/options";

const ManageBannerModal = ({
  isOpen,
  onClose,
  banner = null,
  handleSubmit,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const modalSize = useBreakpointValue({ base: "full", md: "xl" });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader
          backgroundColor="gray.800"
          borderTopRadius="12px"
          borderBottom="1px solid rgba(55, 63, 72, 1)"
          mb={4}
        >
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              เพิ่ม / แก้ไข แบนเนอร์
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              name: banner?.name || "",
              content: banner?.content || "",
              actionLink: banner?.actionLink || "",
              imageUrl: banner?.image || "",
              device: banner?.device || "",
              language: banner?.language || "TH",
              sort: banner?.sort || 1,
              status: banner?.status || true,
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, banner, actions, onClose);
            }}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Box>
                  <Grid
                    templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                    gap={4}
                  >
                    <Box>
                      <FormLabel>
                        รูปแบนเนอร์{" "}
                        <Box as="span" color="green.500" fontWeight="normal">
                          (ขนาด 1280 x 460 px)
                        </Box>
                      </FormLabel>
                      <ImageUploader
                        initialImage={values.imageUrl}
                        onUpload={(uri) => setFieldValue("imageUrl", uri)}
                      />
                    </Box>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                      <FormControl>
                        <FormLabel>ชื่อโปรโมชั่น</FormLabel>
                        <Input
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          fontSize="sm"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                      <FormControl>
                        <FormLabel>คอนเทนต์</FormLabel>
                        <Input
                          name="content"
                          onChange={handleChange}
                          value={values.content}
                          fontSize="sm"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                      <FormControl>
                        <FormLabel>ลิ้งค์</FormLabel>
                        <Input
                          name="actionLink"
                          onChange={handleChange}
                          value={values.actionLink}
                          fontSize="sm"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                      <FormControl>
                        <FormLabel>ลำดับ</FormLabel>
                        <Input
                          name="sort"
                          onChange={handleChange}
                          value={values.sort}
                          fontSize="sm"
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                      <FormControl>
                        <FormLabel>อุปกรณ์</FormLabel>
                        <Select
                          name="device"
                          onChange={handleChange}
                          value={values.device}
                          fontSize="sm"
                          placeholder="เลือกอุปกรณ์"
                          borderRadius="33px"
                        >
                          {DEVICE_OPTIONS.map((type, id) => (
                            <option key={id} value={type.val}>
                              {type.text}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem pt={2}>
                      <Flex>
                        <Switch
                          isChecked={values.status === "true"}
                          onChange={(e) =>
                            setFieldValue("status", e.target.checked.toString())
                          }
                        />
                        <Text mb={0} fontSize="16px" pl={4}>
                          สถานะเปิดใช้งาน
                        </Text>
                      </Flex>
                    </GridItem>
                  </Grid>
                </Box>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    fontSize="14px"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageBannerModal;
