import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useMemberTransactionReport } from "hooks/business/member-report/useMemberTransactionReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { memberTransactionColumnsData } from "variables/columns/member-report/memberTransactionColumnsData";
import { QueryKeys } from "variables/queryKeys";

// fix pagination
const MemberTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "listDateFrom",
    to: "listDateTo",
  };
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";

  const { fetchData, downloadableData } = useMemberTransactionReport();
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text fontWeight="bold" fontSize="24px" color="green.500">
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="38px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                รายงานฝากถอนสมาชิก
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Box>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <Flex direction="column" align="flex-end" mb="24px" w="100%">
                <Box width="100%">
                  <QueryClientProvider client={queryClient}>
                    <SearchTableRemote
                      query={QueryKeys.MEMBER_TRANSACTION_REPORT}
                      columnsData={memberTransactionColumnsData()}
                      fetchData={fetchData}
                      search={search}
                      setSearch={setSearch}
                      showExport={true}
                      downloadableData={downloadableData}
                      filename={pageName}
                    />
                  </QueryClientProvider>
                </Box>
              </Flex>
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default MemberTransactionReport;
