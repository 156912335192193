import { Flex, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";
import { useMemberLoginReport } from "hooks/business/website-report/useMemberLoginReport";
import React from "react";
import { memberLoginHistoryColumnsData } from "variables/columns/website-report/memberLoginHistoryColumnsData";

const MemberLoginReport = ({ pageName }) => {
  const { data: memberLoginHistoryData, isLoading } = useMemberLoginReport();
  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="38px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                ประวัติการเข้าสู่ระบบ
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <SearchTable1
                tableData={memberLoginHistoryData}
                columnsData={memberLoginHistoryColumnsData}
                isLoading={isLoading}
              />
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default MemberLoginReport;
