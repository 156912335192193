import {
  // Avatar,
  Box,
  Button,
  Flex,
  Image, // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import CN from "assets/img/flags/CN.png";
import TH from "assets/img/flags/TH.png";
import UK from "assets/img/flags/UK.png";
import useDashRoutes from "hooks/common/useDashRoutes";
import { useErrorHandler } from "hooks/common/useErrorHandler";
// import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// import avatar8 from "../../assets/img/avatars/avatar8.png";
// import { SettingsIcon } from "@chakra-ui/icons";
import { fetchPendingTransaction } from "../../services/admin/adminProfileService";
import { ERROR_MESSAGE } from "../../variables/system";
// import { HiOutlineLogout } from "react-icons/hi";
import NotificationWebsocket from "../Notification/NotificationWebsocket";
import { SidebarResponsive } from "../Sidebar/Sidebar";

export default function HeaderLinks(props) {
  // const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  // const settingsRef = React.useRef();
  // const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [task, setTask] = React.useState({
    depositTask: 0,
    withdrawTask: 0,
  });

  const history = useHistory();
  const handleError = useErrorHandler();

  // const toggleFullscreen = () => {
  //   if (!document.fullscreenElement) {
  //     document.documentElement.requestFullscreen();
  //     setIsFullscreen(true);
  //   } else if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //     setIsFullscreen(false);
  //   }
  // };

  const showFullscreenIcon = useBreakpointValue({ base: false, md: true });

  const routes = useDashRoutes();

  useEffect(() => {
    fetchPendingTransaction()
      .then((response) => {
        setTask(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  const handleLanguageChange = (language) => {
    console.log(`Selected language: ${language}`);
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      justifyContent="flex-end"
    >
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
      />
      {showFullscreenIcon && (
        <Box display="flex" alignItems="center">
          <Button
            variant="outline"
            h="30px"
            p="3"
            mr="2"
            borderRadius="24px"
            borderColor="green.500"
            onClick={() => history.push("/admin/transaction-report/deposit")}
          >
            <Text fontWeight="normal" fontSize="sm" color="#fff">
              รอฝาก
            </Text>
            <Text
              ml="2"
              fontSize="xs"
              bg="green.500"
              px="1"
              borderRadius="100px"
              color="#fff"
            >
              {task.depositTask}
            </Text>
          </Button>
          <Button
            variant="outline"
            h="30px"
            p="3"
            borderRadius="24px"
            borderColor="red.500"
            onClick={() => history.push("/admin/transaction-report/withdraw")}
          >
            <Text fontWeight="normal" fontSize="sm" color="#fff">
              รอถอน
            </Text>
            <Text
              ml="2"
              fontSize="xs"
              bg="red.500"
              px="1"
              borderRadius="100px"
              color="#fff"
            >
              {task.withdrawTask}
            </Text>
          </Button>
          <NotificationWebsocket setTask={setTask} />
        </Box>
      )}
      <Button
        onClick={() => handleLanguageChange("EN")}
        ml="2"
        background="transparent"
        p="0"
      >
        <Image
          src={CN}
          alt="English"
          width="32px"
          height="24px"
          borderRadius="5px"
        />
      </Button>
      <Button
        onClick={() => handleLanguageChange("FR")}
        background="transparent"
        p="0"
      >
        <Image
          src={UK}
          alt="French"
          width="32px"
          height="24px"
          borderRadius="5px"
        />
      </Button>
      <Button
        onClick={() => handleLanguageChange("ES")}
        background="transparent"
        p="0"
      >
        <Image
          src={TH}
          alt="Spanish"
          width="32px"
          height="24px"
          borderRadius="5px"
        />
      </Button>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
