import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { memberFirstDepositReportColumnsData } from "variables/columns/member-report/memberFirstDepositReportColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { MembersSearchTypes } from "variables/types";

const MemberFirstDepositReport = ({ pageName }) => {
  const queryClient = useQueryClient();
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.FIRST_DEPOSIT,
    memberFirstDepositReportColumnsData,
  );

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="38px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                รายงานสมาชิกฝากครั้งแรก
              </Text>
            </Flex>
            <Spacer />
            <Box>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Box>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <Flex direction="column" align="flex-end" mb="24px" w="100%">
                <Box width="100%">
                  <QueryClientProvider client={queryClient}>
                    <SearchTableRemote
                      query={QueryKeys.MEMBER_FIRST_DEPOSIT_REPORT}
                      columnsData={memberFirstDepositReportColumnsData()}
                      fetchData={fetchData}
                      search={search}
                      setSearch={setSearch}
                      showExport={true}
                      downloadableData={downloadableData}
                      filename={pageName}
                    />
                  </QueryClientProvider>
                </Box>
              </Flex>
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default MemberFirstDepositReport;
