import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import avatar8 from "assets/img/avatars/avatar8.png";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Form, Formik } from "formik";
import { useAdminProfile } from "hooks/business/useAdminProfile";
import React from "react";

const AdminProfile = ({ pageName }) => {
  const { data: adminData, handleSubmit, isSaving } = useAdminProfile();
  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="6px"
          >
            {pageName}
          </Text>
        </Animated>
        <Stack direction="column" spacing="24px">
          <Formik
            initialValues={{
              username: adminData?.username || "",
              roleCode: adminData?.role || "",
              fullName: adminData?.fullName || "",
              tel: adminData?.tel || "",
              password: null,
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, handleChange }) => (
              <Form>
                <Card>
                  <CardHeader mb="22px">
                    <Text fontSize="18px" color="#fff" fontWeight="bold">
                      แก้ไขข้อมูลส่วนตัว
                    </Text>
                  </CardHeader>
                  <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                    <CardBody>
                      <Flex w="100%">
                        <Box
                          flex="1"
                          fontWeight="normal"
                          alignItems="center"
                          border="1px solid #373F48"
                          bg="#1D2229"
                          alignContent="center"
                          justifyItems="center"
                          mr="16px"
                          borderRadius="16px"
                        >
                          <Avatar src={avatar8} size="xl" />
                          <Text
                            color="green.500"
                            fontSize="20px"
                            fontWeight="bold"
                            mt="12px"
                          >
                            {adminData?.username}
                          </Text>
                          <Text fontSize="12px" mt="6px">
                            {adminData?.role}
                          </Text>
                        </Box>
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          w="100%"
                          gap={6}
                          flex="2"
                        >
                          <FormControl>
                            <FormLabel>ยูสเซอร์เนม</FormLabel>
                            <Input
                              name="username"
                              readOnly
                              backgroundColor="#373F48"
                              value={values.username}
                              onChange={handleChange}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>ตำแหน่ง</FormLabel>
                            <Input
                              name="roleCode"
                              readOnly
                              backgroundColor="#373F48"
                              value={values.roleCode}
                              onChange={handleChange}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>ชื่อ-นามสกุล</FormLabel>
                            <Input
                              name="fullName"
                              value={values.fullName}
                              onChange={handleChange}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>เบอร์โทร</FormLabel>
                            <Input
                              name="tel"
                              value={values.phoneNumber}
                              onChange={handleChange}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>รหัสผ่าน</FormLabel>
                            <Input
                              name="password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </Grid>
                      </Flex>
                    </CardBody>
                  </Animated>
                  <Animated
                    justify="flex-start"
                    pt="28px"
                    pb="22px"
                    duration="0.8s"
                    delay="0.1s"
                  >
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "150px" }}
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      borderRadius="33px"
                      type="submit"
                      fontSize="14px"
                      fontWeight="normal"
                      isLoading={isSaving}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </Animated>
                </Card>
              </Form>
            )}
          </Formik>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default AdminProfile;
