import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import { useBannerSetting } from "hooks/business/website-setting/useBannerSetting";
import React, { useState } from "react";

import BannerCard from "./components/BannerCard";
import DeleteBannerModal from "./components/DeleteBannerModal";
import ManageBannerModal from "./components/ManageBannerModal";

const BannerSetting = ({ pageName }) => {
  const { bannerData, handleSubmit, handleDeleteBanner, isDeleting } =
    useBannerSetting();
  const [selectedBanner, setSelectedBanner] = useState(null);

  const [modalState, setModalState] = useState({
    manageBannerModalOpen: false,
    deleteBannerModalOpen: false,
  });

  const handleModalOpen = (modal, banner = null) => {
    setSelectedBanner(banner);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedBanner(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text fontSize="24px" fontWeight="bold" color="green.500" mb="24px">
            {pageName}
          </Text>
        </Animated>
        <Card pb="64px">
          <CardHeader pb="32px" pt="12px">
            <Flex justifyContent="space-between" w="100%">
              <Text fontSize="18px" fontWeight="bold" color="#fff">
                จัดการตั้งค่าแบนเนอร์เว็บไซต์
              </Text>
              <Button
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "150px" }}
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                variant="solid"
                borderRadius="33px"
                fontSize="14px"
                fontWeight="normal"
                onClick={() => handleModalOpen("manageBannerModal")}
              >
                <Icon as={CreateIcon} mr="5px" />
                เพิ่มแบนเนอร์
              </Button>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={6}
              w="100%"
            >
              {bannerData?.map((banner) => (
                <Box key={banner.id}>
                  <BannerCard
                    banner={banner}
                    onEdit={() => handleModalOpen("manageBannerModal", banner)}
                    onDelete={() =>
                      handleModalOpen("deleteBannerModal", banner)
                    }
                  />
                </Box>
              ))}
            </SimpleGrid>
          </Animated>
        </Card>
        <ManageBannerModal
          isOpen={modalState.manageBannerModalOpen}
          onClose={() => handleModalClose("manageBannerModal")}
          banner={selectedBanner}
          handleSubmit={handleSubmit}
        />
        <DeleteBannerModal
          isOpen={modalState.deleteBannerModalOpen}
          onClose={() => handleModalClose("deleteBannerModal")}
          banner={selectedBanner}
          handleSubmit={handleDeleteBanner}
          isSubmitting={isDeleting}
        />
      </Stack>
    </Flex>
  );
};

export default BannerSetting;
