import { Flex, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { newMemberTodayColumnsData } from "variables/columns/member-report/newMemberTodayColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { MembersSearchTypes } from "variables/types";

const NewMemberTodayReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;
  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.ALL,
    newMemberTodayColumnsData,
  );
  const { search, setSearch } = useSearch(dateFieldsNames, dateSearchFormat);

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="38px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column" justifyContent="center">
              <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                สมาชิกใหม่ทั้งหมด
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.NEW_MEMBER_TODAY_REPORT}
                  columnsData={newMemberTodayColumnsData()}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                />
              </QueryClientProvider>
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default NewMemberTodayReport;
