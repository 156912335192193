import { Flex, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";
import { useStaffSetting } from "hooks/business/system-setting/useStaffSetting";
import React, { useState } from "react";
import { staffSettingColumnsData } from "variables/columns/system-setting/staffSettingColumnsData";

import ManageStaffModal from "./components/ManageStaffModal";

const StaffSetting = ({ pageName }) => {
  const { staffData, isLoading, staffRolesData, handleSaveStaff } =
    useStaffSetting();

  const [modalState, setModalState] = useState({
    manageStaffModalOpen: false,
  });

  const [selectedStaff, setSelectedStaff] = useState(null);

  const handleModalOpen = (modal, staff = null) => {
    setSelectedStaff(staff);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedStaff(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" mb="24px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                ตั้งค่าพนักงาน
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
            <CardBody>
              <SearchTable1
                isLoading={isLoading}
                tableData={staffData}
                columnsData={staffSettingColumnsData(handleModalOpen)}
                showAction={true}
                handleModalOpen={handleModalOpen}
                actionButtonText={"เพิ่มพนักงาน"}
                modalName={"manageStaffModal"}
                actionButtonIcon={null}
              />
            </CardBody>
          </Animated>
        </Card>
        <ManageStaffModal
          isOpen={modalState.manageStaffModalOpen}
          onClose={() => handleModalClose("manageStaffModal")}
          staff={selectedStaff}
          staffRolesData={staffRolesData}
          handleSubmit={handleSaveStaff}
        />
      </Stack>
    </Flex>
  );
};

export default StaffSetting;
