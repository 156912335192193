import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";
import React from "react";
import { memberLevelColumnsData } from "variables/columns/member-management/memberLevelColumnsData";

const MemberLevel = ({ pageName }) => {
  const {
    memberLevelData,
    isLoading,
    isSaving,
    saveMemberLevel,
    handleValueChange,
  } = useMemberLevel();

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </Animated>
        <Card px="22px" pb="38px">
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                จัดการระดับสมาชิก
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <SimpleTable
                tableData={memberLevelData}
                columnsData={memberLevelColumnsData(handleValueChange)}
                isLoading={isLoading}
              />
            </CardBody>
          </Animated>
          <Flex justify="flex-start" mt={4}>
            <Button
              onClick={saveMemberLevel}
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              width={{ base: "100%", md: "auto" }}
              minW={{ base: "none", md: "195px" }}
              fontWeight="normal"
              fontSize="14px"
              isLoading={isSaving}
            >
              บันทึกข้อมูล
            </Button>
          </Flex>
        </Card>
      </Stack>
    </Flex>
  );
};

export default MemberLevel;
