import { Box, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";
import Card from "components/Card/Card";
import IconBox from "components/Icons/IconBox";
import {
  TransactionApproveIcon,
  TransactionPendingIcon,
} from "components/Icons/Icons";
import React from "react";

const BigReportCard = ({
  isDeposit,
  mainIcon,
  mainAmount,
  isInteger,
  mainCount,
  firstAmount,
  firstCount,
  secondAmount,
  secondCount,
  isLoading,
}) => {
  const mainText = isDeposit ? "ยอดฝาก" : "ยอดถอน";
  const firstText = isDeposit ? "ฝาก (อนุมัติ)" : "ถอน (อนุมัติ)";
  const secondText = isDeposit ? "ฝาก (รอการตรวจสอบ)" : "ถอน (รอการตรวจสอบ)";
  const mainColor = isDeposit ? "#31AA93" : "#BB344C";
  const mode = isDeposit ? "Deposit" : "Withdraw";

  const cleanMainAmount = parseFloat(mainAmount?.replace(/,/g, ""));
  const cleanFirstAmount = parseFloat(firstAmount?.replace(/,/g, ""));
  const cleanSecondAmount = parseFloat(secondAmount?.replace(/,/g, ""));

  return (
    <Card border={`1px solid ${mainColor}`} bgColor="gray.900">
      <Flex justify="space-between" align="center">
        <Box flex="2">
          <Flex align="center">
            <IconBox
              w={{ base: "30px", md: "62px" }}
              h={{ base: "30px", md: "62px" }}
              mb="16px"
              backgroundColor={mainColor}
            >
              {mainIcon}
            </IconBox>
            <Stack spacing="1" ml="4">
              {isLoading && !cleanMainAmount ? (
                <span className="loader"></span>
              ) : (
                <AnimatedNumber
                  n={cleanMainAmount}
                  dp={isInteger ? 0 : 2}
                  mode={mode}
                  fontSize="32px"
                />
              )}
              <Text fontSize="14px" color="#fff">
                {mainText}
              </Text>
              <Text fontSize="14px" color="gray.500">
                {mainCount + " รายการ"}
              </Text>
            </Stack>
          </Flex>
        </Box>

        <Box flex="3">
          <Stack>
            <Flex
              align="center"
              justify="space-between"
              pb={4}
              borderBottom="1px solid #373F48"
            >
              <Flex align="center">
                <Icon
                  as={TransactionApproveIcon}
                  color="#8C9592"
                  mr="4"
                  boxSize="22px"
                />
                <Stack spacing="0">
                  <Text fontSize="14px" color="#fff">
                    {firstText}
                  </Text>
                  <Text fontSize="12px" color="#8C9592">
                    {firstCount + " รายการ"}
                  </Text>
                </Stack>
              </Flex>
              {isLoading && !cleanFirstAmount ? (
                <span className="loader"></span>
              ) : (
                <AnimatedNumber
                  n={cleanFirstAmount}
                  dp={isInteger ? 0 : 2}
                  mode={mode}
                  fontSize="24px"
                />
              )}
            </Flex>
            <Flex align="center" justify="space-between" pt={2}>
              <Flex align="center">
                <Icon
                  as={TransactionPendingIcon}
                  boxSize="22px"
                  color="#8C9592"
                  mr="4"
                />
                <Stack spacing="0">
                  <Text fontSize="14px" color="#fff">
                    {secondText}
                  </Text>
                  <Text fontSize="12px" color="#8C9592">
                    {secondCount + " รายการ"}
                  </Text>
                </Stack>
              </Flex>
              {isLoading && !cleanSecondAmount ? (
                <span className="loader"></span>
              ) : (
                <AnimatedNumber
                  n={cleanSecondAmount}
                  dp={isInteger ? 0 : 2}
                  mode={mode}
                  fontSize="24px"
                />
              )}
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Card>
  );
};

export default BigReportCard;
